// AsistenciaModal.js
import Cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';


const ModalAsistencia = ({
  showModal,
  handleClose,
  datos,
  funciones,
  selectedFuncion,
  setSelectedFuncion,
  desact,
  setDesact,
  registrarAsistencia,
  horaConsulta
}) => {

  return (
    <Modal show={showModal} onHide={handleClose} className="custom-modal" size={'lg'} centered>
      <Modal.Header closeButton className="text-center" style={{ backgroundColor: '#025E73' }}>
        <Modal.Title className="titulomodalregistro" style={{ "color": "#fff",textAlign:'center' ,width:'100%'}}>REGISTRO DE INCIDENCIA</Modal.Title>
      </Modal.Header>
        <Modal.Body className="datos">
          {datos && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '5px' }}>
                <p className="nombresapellidosmodal">Nombres: <strong >{datos.SERE_chNOM} {datos.SERE_chAPEPAT} {datos.SERE_chAPEMAT}</strong></p>
                <p className="nombresapellidosmodal">DNI: <strong>{datos.SERE_P_chDNI}</strong></p>
              </div>
              <div className="row">
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ marginRight: '20px' }} className="size12RV">Función:</p>
                  {selectedFuncion ?  <input disabled type='text' className='form-control' value={selectedFuncion}/> :
                  <Select
                    className="w-100 SelectFuncion"
                    options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                    onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                    isSearchable
                    placeholder="Selecciona una Función"
                  /> }
                </div>
                
              </div>
              <div style={{ justifyContent: 'center', width: '100%', display: 'flex', marginTop: '2rem' }}>
                  <textarea value={desact}
                        onChange={(e) => setDesact(e.target.value)} className='form-control' placeholder='Describe la incidencia' style={{margin:'auto',width:'90%'}}></textarea>
                </div>
                <div style={{ justifyContent: 'center', width: '100%', display: 'flex', marginTop: '10px' }}>
                  <p >Hora: <strong className="fechayhora">{horaConsulta}</strong></p>
                </div>
                <div className="botonesmodal" style={{marginTop:'2rem'}}>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      registrarAsistencia("falto");
                    }}
                    style={{width:'30%'}}
                  >
                    Registrar Falto
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={() => {
                      registrarAsistencia("Desc");
                    }}
                    style={{width:'30%'}}
                  >
                    Registrar Descanso
                  </Button>
                  <Button
                    className="btn btn-success"
                    onClick={() => {
                      registrarAsistencia("Comp");
                    }}
                    style={{width:'30%'}}
                  >
                    Registrar Compensación
                  </Button>
                </div>


                <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'2rem'}}>
                <Button className="btn btn-danger" onClick={handleClose} style={{width:'50%'}}>
                    Cerrar
                  </Button>
                </div>
            </div>
          )}
        </Modal.Body>
    
    </Modal>
  );
}

export default ModalAsistencia;