import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';
import Swal from 'sweetalert2';
import '../CSS/App.css';

function RegistroaQr() {
  const [result, setResult] = useState('');
  const [datos, setDatos] = useState(null);
  const [codigo, setCodigo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalPhoto, setShowModalPhoto] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [nombreSereno, setNombreSereno] = useState("");
  const [fechaConsulta, setFechaConsulta] = useState("");
  const [horaConsulta, setHoraConsulta] = useState("");
  const [latitud, setLatitud] = useState("")
  const [longitud, setLongitud] = useState("")
  const [photoURL, setPhotoURL] = useState('');

  console.log(latitud, ",",longitud)
  const takePhoto = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);
      const photoBlob = await imageCapture.takePhoto();
      const photoDataURL = URL.createObjectURL(photoBlob);
      setPhotoURL(photoDataURL);
      videoTrack.stop(); // Detener la cámara después de capturar la foto
    } catch (error) {
      console.error('Error al capturar la foto:', error);
    }
  };
  const obtenerUbicacion = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLatitud(latitude);
        setLongitud(longitude);
      }, (error) => {
        console.error('Error al obtener la ubicación:', error);
        // Manejar el error según sea necesario
      });
    } else {
      console.error('La geolocalización no es compatible con este navegador.');
      // Manejar la falta de soporte para geolocalización
    }
  };
  useEffect(() => {
    obtenerUbicacion();

  }, []);
  const codigocookie = Cookies.get("codigo");
  const codigoResultado = codigocookie ? codigocookie.replace("vic.qrs.pe?fun=", "") : "";

  const handleClose = () => {
    setShowModal(false);
    setErrorMessage(""); 
  };
  
  const handleClosePhoto = () => {
    setShowModalPhoto(false);
    setErrorMessage(""); 
  };
  const handleTomarPhoto = () => {
    setShowModalPhoto(true);
    takePhoto(); 
  };
  const handleShow = () => setShowModal(true);
  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(`http://190.117.85.58:5005/M_MAES_SEREN/1/${codigoResultado}/`);
      setDatos(respuesta.data);
      setErrorMessage('!Se encontró los datos');
      handleShow();
      obtenerUbicacion();
      const dni = respuesta.data.SERE_P_chDNI;
      const fechaActual = new Date();
      const horaActual = new Date();
      const horaConsultaFormato = horaActual.toLocaleTimeString();
      const fechaConsultaFormato = fechaActual.toLocaleDateString().replace(/\//g, '');
      setNombreSereno(dni);
      setFechaConsulta(fechaConsultaFormato);
      setHoraConsulta(horaConsultaFormato);
      Cookies.set("horaConsulta", horaConsultaFormato);
      Cookies.set("fechaConsulta", fechaConsultaFormato);
      Cookies.set("dnisereno", dni);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setErrorMessage('!La placa no se encuentra registrada¡');
    }
  };

  const handleScan = (data) => {


    if (data) {
      console.log('Datos Escaneados:', data);
      setResult(data);
    }
  }
  
  const handleError = (err) => {
    console.error('Error al acceder a la cámara:', err);
  }
  const handleResult = (result) => {
    if (result) {
      console.log('Resultado del escaneo:', result);
      
      setResult(result);
      Cookies.set("codigo", result);
    }
  };
  
  const registrarAsistencia = async () => {
    try {
      const dni = Cookies.get("dnisereno");
      const horaGuardada = Cookies.get("horaConsulta");
      const fechaguardada = Cookies.get("fechaConsulta");

      if (!dni || !horaGuardada || !fechaguardada) {
        console.error('No hay datos guardados para registrar la asistencia.');
        return;
      }

      const datosAsistencia = {
        sere_p_chdni: dni,
        asis_chhor: horaGuardada,
        asis_chfec: fechaguardada,

      };

      const respuestaPost = await axios.post("http://190.117.85.58:5005/asis_sereno/", datosAsistencia);

      console.log('Respuesta de la API:', respuestaPost.data);
      Swal.fire({
        icon: 'success',
        title: 'Registro de asistencia',
        text: 'La asistencia se registró correctamente.',
      });
    } catch (error) {
      console.error('Error al registrar la asistencia:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error al registrar la asistencia',
        text: 'El usuarió ya registró su asistencia Hoy.',
      });
    }
  };
  const qrReaderStyle = {
    width: '80%',
    objectFit: 'cover',
    borderRadius: '8px',
  };
  const handleLogout = () => {

    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");

    // Redirect to the login page or any other desired page
    window.location.replace('/');
  };
  return (
    <div>

    <nav>
    <a className="atras" href="/principal"><i class="fa-solid fa-arrow-rotate-left"></i> Atras</a>
    <a className="salir" onClick={handleLogout}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>

    </nav>
    <div style={{ width: '100%', maxWidth: '400px', margin: 'auto' }}>
    <QrReader
      delay={300}
      onError={handleError}
      onScan={handleScan}
      onResult={handleResult}  
      style={qrReaderStyle}
    />
        <div className="input-container">
        <label htmlFor="placaInput">N° de codigo: {codigoResultado}</label>
        <div>
    </div>
      </div>
      <Modal show={showModalPhoto} onHide={handleClosePhoto} className="custom-modal" centered>
        <Modal.Body className="datos">
          {photoURL && <img src={photoURL} alt="Captured" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePhoto}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleClose} className="custom-modal">
        <Modal.Header className="header">
            <h2 className="titulodatos">Datos del Sereno  </h2>       

        </Modal.Header>
        <Modal.Body className="datos">
          {datos && (
            <div>
              <p>Nombres: {datos.SERE_chNOM}</p>
              <p>Apellidos: {datos.SERE_chAPEPAT} {datos.SERE_chAPEMAT}</p>
              <p>Telefono: {datos.SERE_chTELEF}</p>
              <p>DNI: {datos.SERE_P_chDNI}</p>
              <div className="botonesmodal">
           
              <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="primary"  onClick={registrarAsistencia}>
              Registrar Asistencia
            </Button>
              </div>
            </div>
            
          )}
        </Modal.Body>

      </Modal>
      <div style={{display:'flex', width:'100%',gap:'10px', justifyContent:'center',alignItems:'center',marginTop:'15px'}}>
      <button className="btn btn-primary" onClick={obtenerDatos} style={{padding:'10px 20px'}}>Obtener Datos</button>
      <button  className="btn btn-dark" onClick={handleTomarPhoto} style={{padding:'10px 20px',color:'white'}}><i class="fa-solid fa-camera"></i></button>
      </div>


    </div>
    </div>
  )
}

export default RegistroaQr