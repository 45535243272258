export const PublicRoutes ={
    AUTH: '/' ,
    LOGIN: 'login' 
}
export const RoutesPrivate={
    DASHBOARD: '/principal',
    ASISTENCIADNI: '/asistencia-dni',
    ASISTENCIAQR: '/asistencia-qr',


}