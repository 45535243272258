import {Navigate, Route, Routes} from 'react-router-dom'
import { RoutesPrivate } from './ProtectedRoute'
import Principal from '../Pages/Asistencia/Principal'
import RegistroaQr from '../Pages/Asistencia/RegistroaQr'
import Registroasistencia from '../Pages/Asistencia/Registroasistencia'
import Cookies from 'js-cookie'




const PrivateRoutes = () => {

    const rol = Cookies.get('rol');

  return (
    <Routes>
        <Route path='/*' element={<Navigate to={RoutesPrivate.DASHBOARD} />} />
        <Route path={RoutesPrivate.DASHBOARD} element={<Principal />} />
        <Route path={RoutesPrivate.ASISTENCIADNI} element={<Registroasistencia />} />
        <Route path={RoutesPrivate.ASISTENCIAQR} element={<RegistroaQr />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}
export {PrivateRoutes}
